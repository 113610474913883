.pagination-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-top: 10px;
}

.pagination-info {
  grid-column: 1 / span 1;
  font-size: 16px;
  color: RGB(19, 19, 19, 0.7);
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fabd33 !important;
}

.pagination {
  grid-column: 2 / span 1;
}

.pagination button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  color: var(--black);
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination button.active {
  background-color: #fabd33;
  color: var(--black);
  border-color: #fabd33;
}
