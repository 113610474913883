
.loader {
    width          : 100vw;
    height         : 100vh;
    display        : flex;
    justify-content: center;
    align-items    : center;
    position: fixed;
    top: 0;
    background-color: #cacacae2;
    z-index: 99999999999;
  }
