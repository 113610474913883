.btn_wrapper {
  position: relative;
}
.btn_wrapper .loader {
  position: absolute;
  top: 20%;
  left: 50%;
  border: 4px solid #000000;
  /*Lightgrey*/
  border-top: 4px solid #F9BD34;
  /*Blue*/
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
