.screen_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-8);
  border-radius: 1rem;
  padding: 1.2rem 4.4rem;
  /* margin: 4.8rem 0 2.4rem; */
  margin-bottom: 1rem;
}
.screen_heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white-2);
}
