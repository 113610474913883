.payment_card_container{
    border: 1px solid var(--primary-color);
    /* width: max-content; */
    padding: 20px 50px 20px 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;   
}


/* .payment_card_container p {
    font-size: 19px;
    color: var(--black);
    font-weight: 700;
}
.payment_card_container span {
    font-size: 16px;
    color: var(--gray-3);
    font-weight: 700;
} */

