/* #dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-row-gap: 40px;
  }
  
  .card {
    background-color: var(--gray-8); /* Set background color */
    border: 1px solid var(--primary-color); /* Add border for styling */
    border-radius: 8px; /* Add border-radius for rounded corners */
    padding: 20px;
    min-height: 150px; /* Add padding for spacing inside the card */
  }
  
  .card h3 {
    font-size: 30px;
    padding-top: 40px;
  }
  .card p {
    color: white;
    font-size: 20px;
  }
  .card:hover{

    border:1px solid #ccc
  }
  
  .dashboard_crud_holder {
    width: 100%;
    height: 100%;
    padding: 4rem 0 2rem;
    overflow-y: auto;
  }
  

  .pay_btn {
    background-color: var(--primary-color);
    color: #312d2d;
    font-size: 20px;
    text-align: center;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 5rem;
    font-weight: 700;
    display: flex;
    gap: 14px;
    align-items: center;
  }