.file_upload_input[type="file"] {
  display: none;
}
.preview_image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
}
.preview_image img {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
}
/* .preview_image img {
    max-width     : 100%;
    object-fit: cover;
} */

input[type="file"] {
  opacity: 0;
  height: 100px;
  width: 100%;
}
